import React from 'react';
import * as S from './ContactLink.style';

const ContactLink = ({ icon, children }) => (
  <S.ContactContainer>
    {icon && <S.Icon>{icon}</S.Icon>}
    <S.Text>{children}</S.Text>
  </S.ContactContainer>
);

export default ContactLink;
