import React from 'react';
import * as S from './DownloadLink.style';

const DownloadLink = ({ text, url, fileName }) => (
  <S.DownloadLink href={url} target="_blank">
    {text}
  </S.DownloadLink>
);

export default DownloadLink;
