import styled from 'styled-components';

export const Separator = styled.div`
  background-color: #222;

  height: 0.01rem;
  margin: 0 auto;
  margin-top: 2rem;
  /* flex: 1; */
  width: 80%;
`;
