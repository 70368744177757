import styled from 'styled-components';

export const Timeline = styled.div`
  :not(:last-child) {
    margin-bottom: 3.5rem;
  }
`;

export const PeriodContainer = styled.div`
  align-items: center;
  color: #888;
  display: flex;
  /* margin-bottom: 0.5rem; */
`;

export const Period = styled.div`
  font-size: 0.9rem;
  margin-right: 1rem;
`;

export const Separator = styled.div`
  background-color: #333;
  height: 0.01rem;
  flex: 1;
  /* width: 100%; */
`;

export const Title = styled.h4`
  font-size: 0.95rem;
  line-height: 1.4rem;
  margin-bottom: 0.7rem;
  margin-top: 1rem;
  /* text-transform: uppercase; */
`;

export const Company = styled.div`
  /* color: #888; */
  line-height: 1.3rem;
  margin-bottom: 0.5rem;
`;

export const Location = styled.span`
  color: #888;
  /* margin-bottom: 2rem; */
`;

export const Description = styled.div`
  margin-top: 2rem;
`;
