import styled from 'styled-components';

export const ProjectContainer = styled.div`
  margin-bottom: 3.5rem;

  @media only screen and (min-width: 768px) {
    display: flex;

    /* margin-bottom: 2rem; */

    /* :first-child() {
    margin-right: 2rem;
  } */
    /* & > :first-child {
    margin-right: 1rem;
  } */

    & > :last-child {
      /* background: red; */
      margin-left: 2rem;
    }

    :nth-of-type(2n) {
      flex-direction: row-reverse;

      & > :last-child {
        /* background: red; */
        margin-left: 0;
        margin-right: 2rem;
      }
    }
  }
`;

export const ProjectImage = styled.div`
  display: inline-block;
  margin: 0 auto;
  max-width: 18.5rem;
  /* margin-right: 2rem; */
  /* border: 0.2rem solid #d9556c; */
  align-items: center;
  display: flex;
  flex: 1;
  /* width: 50%; */
  /* height: 100%; */
  /* max-width: 600px; */

  @media only screen and (min-width: 768px) {
    max-width: none;
  }
`;

export const ProjectDetails = styled.div`
  flex: 1;
  justify-content: flex-start;
`;

export const Title = styled.h5`
  color: #d9556c;
  font-size: 1rem;

  margin-bottom: 0.75rem;
`;

export const Links = styled.div``;

export const IconLink = styled.div``;

export const Description = styled.div`
  font-size: 0.95rem;
`;
