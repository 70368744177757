import styled from 'styled-components';

export const Card = styled.div`
  border: 0.01rem solid #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
  width: fit-content;

  justify-content: center;
  transition: border 0.2s ease-in-out;

  :hover {
    border: 0.01rem solid #d9556c;
    transition: border 0.2s ease-in-out;
  }
`;

export const Logo = styled.div`
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  /* margin-top: 1rem; */
  font-size: ${(props) => (!props.hasIcon ? '6vw' : '4.5vw')};
  /* font-size: 6vw; */

  @media only screen and (min-width: 500px) {
    font-size: ${(props) => (!props.hasIcon ? '1.1rem' : 'initial')};
  }
`;
