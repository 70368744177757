import styled from 'styled-components';

export const DownloadLink = styled.a`
  background: transparent;
  border: 0.2rem solid #d9556c;
  color: #d9556c;
  cursor: pointer;
  display: inline-block;
  font-size: 90%;
  /* font-size: 1.1rem; */
  /* letter-spacing: 0.05rem; */
  margin-top: 1rem;
  padding: 1rem 1.5rem !important;
  text-decoration: none;
  text-transform: uppercase;
  /* width: 100%; */
  white-space: nowrap;

  :hover {
    border-color: #c84a5f;
    color: #c84a5f;
  }

  :active {
    border-color: #c39ca3;
    color: #c39ca3;
  }
`;
