import styled from 'styled-components';

export const ContactContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
`;

export const Icon = styled.div`
  margin-right: 1rem;
`;

export const Text = styled.div``;
