import styled from 'styled-components';

import { StyledLink as Link } from '../Header/Header.style';

const navbarHeight = '3.6rem';

export const NavbarWrapper = styled.div`
  font-weight: 700;
  height: ${navbarHeight};
  margin-bottom: 1rem;
  width: 100%;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const NavbarCont = styled.div`
  align-items: center;
  background: #1e1c1c;
  border: 0.01rem solid #222;
  display: flex;
  justify-content: space-between;
`;

export const Navbar = styled.nav`
  margin-bottom: 1rem;
  min-height: 3.1rem;
  position: fixed;
  top: -0.1rem;
  width: 100%;
  z-index: 999;
`;

export const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem 0;
  padding-left: 1rem;
`;

export const Logo = styled.div`
  align-items: center;
  background: #d9556c;
  border: 0.2rem solid #eee;
  border-radius: 50%;
  display: flex;
  font-size: 1.5rem;
  height: 2.2rem;
  justify-content: center;
  margin-right: 1rem;
  width: 2.2rem;
`;

export const Title = styled.span`
  span {
    color: #d9556c;
  }
`;

export const MobileIcon = styled.div`
  align-items: center;
  align-self: flex-start;
  background: #d9556c;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 3.5rem;
  width: 3.5rem;

  & > * {
    height: 1.75rem;
    width: 1.75rem;
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const LinksContainer = styled.div`
  align-items: center;
  background: #1c1919;
  border-bottom: 0.01rem solid #222;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${navbarHeight} + 0.1rem);
  position: absolute;
  top: -100vh;
  width: 100vw;
  z-index: -999;

  &.visible {
    top: ${navbarHeight};
    transition: top 0.3s ease-in;
  }

  &.hidden {
    top: -100vh;
    transition: top 0.3s ease-in;
  }
`;

export const StyledLink = styled(Link)`
  font-size: 1.5rem;
  margin-top: 1rem;
  /* z-index: -999; */

  :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

// export const InsideLinks = styled.ul`
//   list-style: none;
//   text-align: center;

//   li {
//     font-size: 0.9rem;
//     margin-left: 2rem;
//   }

//   li:before {
//     content: '';
//   }
// `;
