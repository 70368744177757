import React from 'react';
import * as S from './Projects.style';

import { Project } from '../../components';

import fallGuys from '../../assets/fall-guys-screenshot.png';
import indigo from '../../assets/indigo-eshop-screenshot.png';
import recipeBook from '../../assets/recipe-book-screenshot.png';

const Projects = () => (
  <S.Section id="projects" title="Projects">
    <Project
      title="Fall Guys Voting Site"
      image={fallGuys}
      alt="indigo eshop"
      liveLink="https://fall-guys-voting-fe-b6oxf.ondigitalocean.app/"
      frontLink="https://github.com/agne-vensl/fall-guys-voting-fe"
      backLink="https://github.com/agne-vensl/fall-guys-voting-be"
      description={
        <>
          <p>
            A simple project where users can register, login and vote for their
            favourite Fall Guys: Ultimate Knockout skins.
          </p>
          <p>
            <span className="highlight">Technologies used: </span> NodeJS,
            React, MySQL, DigitalOcean
          </p>
        </>
      }
    />

    <Project
      title="Indigo Eshop Portfolio"
      image={indigo}
      alt="indigo eshop"
      liveLink="https://indigo-eshop-fe-jbhq7.ondigitalocean.app/"
      frontLink="https://github.com/agne-vensl/indigo-eshop-fe"
      backLink="https://github.com/agne-vensl/indigo-eshop-be"
      description={
        <>
          <p>
            An e-shop portfolio website that lets users add products, filter
            them by category, add products to favourites.
          </p>
          <p>
            <span className="highlight">Technologies used: </span> NodeJS,
            React, MySQL, DigitalOcean
          </p>
        </>
      }
    />

    <Project
      title="Online Recipe Book"
      image={recipeBook}
      alt="online recipe book"
      liveLink="https://recipe-book-fe-b85l3.ondigitalocean.app/"
      frontLink="https://github.com/agne-vensl/recipe-book-fe"
      backLink="https://github.com/agne-vensl/recipe-book-be"
      description={
        <>
          <p>
            An online recipe book where users can register, login, add new
            recipes, comment on recipes if they are logged in and add recipes to
            favourites.
          </p>
          <p>
            <span className="highlight">Technologies used: </span> NodeJS,
            Vue.js, MySQL, DigitalOcean
          </p>
        </>
      }
    />
  </S.Section>
);

export default Projects;
