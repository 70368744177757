import React from 'react';
import * as S from './TimelineBlock.style';

const TimelineBlock = ({ period, title, company, location, description }) => (
  <S.Timeline>
    <S.PeriodContainer>
      <S.Period>{period}</S.Period>
      <S.Separator />
    </S.PeriodContainer>

    <S.Title className="primary-color highlight">{title}</S.Title>
    <S.Company>{company}</S.Company>
    {location && <S.Location>{location}</S.Location>}
    <S.Description>{description}</S.Description>
  </S.Timeline>
);

export default TimelineBlock;
