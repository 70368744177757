import styled from 'styled-components';

export const IconLink = styled.div`
  /* display: flex; */
  /* align-items: center; */
  margin-bottom: 1rem;
  width: fit-content;
`;

export const Icon = styled.div`
  display: flex;
  align-self: center;
  font-size: 1.3rem;
  margin-right: 0.5rem;
`;

export const LinkUrl = styled.a`
  align-items: center;
  display: flex;
  text-decoration: none;

  /* &:hover {
    text-decoration: underline;
  } */
`;

export const LinkText = styled.span`
  align-items: center;
  display: flex;
`;
