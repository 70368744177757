import React from 'react';
import * as S from './SkillCardContainer.style';

const SkillCardContainer = ({ title, children }) => (
  <>
    <S.Title>{title}</S.Title>
    <S.CardContainer>{children}</S.CardContainer>
  </>
);

export default SkillCardContainer;
