import React from 'react';

import {
  FaHtml5,
  FaCss3Alt,
  FaJs,
  FaJava,
  FaPython,
  FaSass,
  FaNodeJs,
  FaReact,
  FaVuejs,
  FaGitAlt,
  FaBootstrap,
  FaDigitalOcean,
} from 'react-icons/fa';

import {
  SiBulma,
  SiVim,
  SiNetlify,
  SiFirebase,
  SiGlitch,
} from 'react-icons/si';

import { GrMysql } from 'react-icons/gr';

import {
  ModalImage,
  Section,
  Separator,
  SkillCard,
  SkillCardContainer,
  TimelineBlock,
} from '../../components';

import { Subtitle } from '../../components/Section/Section.style';

import frontEnd1 from '../../assets/agne_venslaviciute_front-end_1.png';
import frontEnd2 from '../../assets/agne_venslaviciute_front-end_2.png';
import python from '../../assets/agne_venslaviciute_python.png';

const Resume = () => (
  <Section id="resume" title="Resume">
    <Subtitle id="software-skills">Software Skills</Subtitle>

    <SkillCardContainer>
      <SkillCard title="HTML 5" icon={<FaHtml5 />} />
      <SkillCard title="CSS" icon={<FaCss3Alt />} />
      <SkillCard title="Sass" icon={<FaSass />} />
    </SkillCardContainer>

    <SkillCardContainer title="Programming Languages">
      <SkillCard title="JavaScript" icon={<FaJs />} />
      <SkillCard title="LPC" />
      <SkillCard title="Java" icon={<FaJava />} />
      <SkillCard title="Python" icon={<FaPython />} />
    </SkillCardContainer>

    <SkillCardContainer title="JavaScript Frameworks / Libraries">
      <SkillCard title="NodeJS" icon={<FaNodeJs />} />
      <SkillCard title="React" icon={<FaReact />} />
      <SkillCard title="Vue.js" icon={<FaVuejs />} />
    </SkillCardContainer>

    <SkillCardContainer title="Development Platforms">
      <SkillCard title="DigitalOcean" icon={<FaDigitalOcean />} />
      <SkillCard title="Netlify" icon={<SiNetlify />} />
      <SkillCard title="Firebase" icon={<SiFirebase />} />
      <SkillCard title="Glitch" icon={<SiGlitch />} />
    </SkillCardContainer>

    <SkillCardContainer title="Version Control Systems">
      <SkillCard title="Git" icon={<FaGitAlt />} />
      <SkillCard title="Revision Control System" />
    </SkillCardContainer>

    <SkillCardContainer title="CSS Frameworks">
      <SkillCard title="Bootstrap" icon={<FaBootstrap />} />
      <SkillCard title="Bulma" icon={<SiBulma />} />
    </SkillCardContainer>

    <SkillCardContainer title="Database Management Systems">
      <SkillCard title="MySQL" icon={<GrMysql />} />
    </SkillCardContainer>

    <SkillCardContainer title="Other">
      <SkillCard title="Vim" icon={<SiVim />} />
    </SkillCardContainer>

    <Separator />

    <Subtitle id="experience">Experience</Subtitle>

    <TimelineBlock
      period="2018 - Current"
      title="Liaison and Developer"
      company={
        <span>
          <a
            href="http://discworld.starturtle.net/lpc/"
            target="_blank"
            rel="noreferrer"
          >
            Discworld MUD
          </a>
          <span style={{ color: '#888' }}> (An online text-based game)</span>
        </span>
      }
      description={
        <div>
          <p>
            I am a volunteer <span className="primary-color">developer</span>{' '}
            and <span className="primary-color">liaison</span> on an online
            text-based game (Discworld MUD) using LPC programming language. My
            duties involve{' '}
            <span className="primary-color">content development</span>,{' '}
            <span className="primary-color">bug fixing</span>, supporting the
            playerbase in a technical capacity.
          </p>
          <p>Some of my achievements:</p>
          <ul>
            <li>
              Implemented fully functional projects including a shop for players
              and new features for a player guild
            </li>
            <li>Fixed 577 bugs in my first year</li>
            <li>
              Successfully dealt with situations where I had to communicate with
              multiple players at once while also documenting issues and looking
              for possible solutions
            </li>
          </ul>
        </div>
      }
    />

    <Separator />

    <Subtitle id="education">Education</Subtitle>

    <TimelineBlock
      period="1000-hour course"
      title="Studies 101 – Front-End Web Development"
      company="Code Academy"
      location="Vilnius"
      description={
        <ul>
          <li>
            Firm understanding of CSS/JS selector syntax, responsive design,
            advanced layout techniques
          </li>
          <li>Dynamic document object model (DOM) manipulation</li>
          <li>Asynchronous programming (promises, callbacks, listeners)</li>
          <li>NodeJS integration with express.js, routing, CRUD methods</li>
          <li>
            User register/login, password hashing, JWT token creation,
            middleware functions integration
          </li>
          <li>
            Vue.js: applying props, componentizing projects, using lifecycle
            hooks, Vue directives
          </li>
          <li>
            React: JSX, components, props, prop types, state, lifecycle methods,
            hooks
          </li>
        </ul>
      }
    />

    <TimelineBlock
      period="80-hour course"
      title="Python"
      company="Code Academy"
      location="Vilnius"
      description={
        <ul>
          <li>
            Object oriented programming (classes, properties, get/set methods)
          </li>
          <li>Working with files, directories, databases</li>
          <li>Web scraping</li>
          <li>Unit tests, logging</li>
        </ul>
      }
    />

    <TimelineBlock
      period="2015 - 2018"
      title="Professional Bachelor of Social Sciences"
      company="Panevezys college"
      location="Panevėžys"
      description={
        <ul>
          <li>
            Collecting and organizing information, using information
            technologies, and dealing properly with confidential information.
          </li>
          <li>
            Identifying and assessing the customer's needs and strengths, plan,
            implementing and evaluating the support process and its results.
          </li>
        </ul>
      }
    />

    <Separator />

    <Subtitle id="volunteer-work">Volunteer Work</Subtitle>

    <p>
      I have participated in volunteer work with at-risk children and people
      with disabilities, helping in every day life and offering support wherever
      needed including with medication and self care.
    </p>

    <ul>
      <li>
        Organized and implemented care plans for multiple clients while
        communicating with the healthcare team
      </li>
      <li>Tutored and mentored at-risk children</li>
      <li>
        Assisted with and encouraged independence in activities of daily living
        with people with disabilities
      </li>
    </ul>

    {/* <Separator /> */}

    <Subtitle id="certificates">Certificates</Subtitle>

    <div className="flex">
      <ModalImage src={frontEnd1} alt="front-end diploma" />
      <ModalImage src={frontEnd2} alt="front-end diploma" />
    </div>

    <ModalImage src={python} alt="python certificate" />
  </Section>
);

export default Resume;
