import React from 'react';

import { FaGithub } from 'react-icons/fa';
import { FiMail, FiPhone } from 'react-icons/fi';

import { ContactLink, Section } from '../../components';

const Contacts = () => (
  <Section id="contacts" title="Contacts">
    <div className="flex">
      <div className="flex1">
        <ContactLink icon={<FiMail size="2rem" />}>
          agne.vensl@gmail.com
        </ContactLink>

        <ContactLink icon={<FiPhone size="2rem" />}>+370 609 24847</ContactLink>
      </div>

      <div className="flex1">
        <ContactLink icon={<FaGithub size="2rem" />}>
          <a
            href="https://github.com/agne-vensl"
            target="_blank"
            rel="noreferrer"
          >
            https://github.com/agne-vensl
          </a>
        </ContactLink>
      </div>
    </div>
  </Section>
);

export default Contacts;
