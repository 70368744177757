import React from 'react';
import * as S from './Section.style';

const Section = ({ id, title, children }) => (
  <S.Section id={id}>
    <S.Title>{title}</S.Title>
    {children}
  </S.Section>
);

export default Section;
