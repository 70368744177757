import styled from 'styled-components';
import { Link } from 'react-scroll';

export const StyledLink = styled(Link)`
  /* cursor: pointer;
  padding: 0.2rem;
  transition: color 0.2s ease-in-out;

  :hover {
    color: #d9556c;
    transition: color 0.2s ease-in-out;
  }

  :active {
    color: #d67d8c;
    transition: color 0.2s ease-in-out;
  }

  &.active {
    color: #d9556c;
  } */
`;
