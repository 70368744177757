import React from 'react';

import { Section } from '../../components';

const AboutMe = () => (
  <Section id="about-me" title="About Me">
    <p>
      I am a recent graduate of Code Academy looking to utilise my skills and
      desire to learn more to build a career in programming and development. My
      experience includes collaborating with a team of volunteer developers,
      working under pressure in a live environment while troubleshooting
      problems and serving as a moderator between other developers and users.
    </p>
    <p>
      I am a volunteer developer and liaison on an online text-based game (
      <a
        href="http://discworld.starturtle.net/lpc/"
        target="_blank"
        rel="noreferrer"
        className="styled"
      >
        Discworld MUD
      </a>
      ) using LPC programming language. My duties involve content development,
      bug fixing, supporting the playerbase in a technical capacity, as well as
      acting as a liaison between other creators and the players, and enforcing
      rules.
    </p>

    <p>
      In addition to that, I administrate and host my own online and freely
      accessible text-based game, where friends and I support each other in
      learning more about LPC, and coming up with fun and challenging projects
      and concepts.
    </p>
  </Section>
);

export default AboutMe;
