import styled from 'styled-components';

import { StyledLink as Link } from '../Header/Header.style';

const navbarHeight = '3.5rem';

export const NavbarWrapper = styled.div`
  font-weight: 700;
  height: ${navbarHeight};
  margin-bottom: 1rem;
  width: 100%;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const Navbar = styled.nav`
  background: #1e1c1c;
  margin-bottom: 1rem;
  position: fixed;
  width: 100%;
  z-index: 999;
  border-bottom: 0.01rem solid #222;
  /* padding-left: 1rem; */

  & > .container {
    align-items: center;
    display: flex;
    font-weight: 700;
    justify-content: space-between;
    min-height: 3rem;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  /* @media only screen and (max-width: 767px) {
    display: none;
  } */
`;

export const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  /* padding: 0.5rem; */
`;

export const Logo = styled.div`
  align-items: center;
  background: #d9556c;
  border: 0.2rem solid #eee;
  border-radius: 50%;
  display: flex;
  font-size: 1.5rem;
  height: 2.5rem;
  justify-content: center;
  margin-right: 1rem;
  width: 2.5rem;
`;

export const Title = styled.span`
  span {
    color: #d9556c;
  }
`;

export const LinksContainer = styled.div`
  /* padding-right: 1rem; */
`;

export const StyledLink = styled(Link)`
  :not(:last-child) {
    margin-right: 1rem;
  }
`;
