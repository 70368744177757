import styled from 'styled-components';

import { BsChevronDown } from 'react-icons/bs';
import { Link } from 'react-scroll';

export const Container = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

export const Arrow = styled(BsChevronDown)`
  color: #eee;
`;

export const StyledLink = styled(Link)`
  cursor: pointer;
`;
