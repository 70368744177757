import styled from 'styled-components';

export const ImageContainer = styled.div`
  text-align: center;

  @media only screen and (min-width: 768px) {
    text-align: left;
  }
`;

export const Image = styled.img`
  border-radius: 0.3rem;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  max-width: ${(props) => (props.size?.width ? props.size.width : '18.5rem')};

  :hover {
    opacity: 0.85;
  }
`;

export const Modal = styled.div`
  align-items: center;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  height: 100%;
  position: fixed;
  padding-top: 5rem;
  left: 0;
  overflow: auto;
  top: 0;
  width: 100%;
  z-index: 1000;

  @media only screen and (min-width: 768px) {
    padding-top: 0;
  }
`;

export const ModalImage = styled.img`
  display: block;
  width: 80%;
  max-width: 55rem;

  animation-name: zoom;
  animation-duration: 0.6s;

  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CloseButton = styled.span`
  color: #f1f1f1;
  position: absolute;
  top: 15px;
  right: 35px;

  font-size: 2.5rem;
  font-weight: bold;
  transition: 0.3s;

  :hover,
  :focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
`;
