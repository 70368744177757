import React from 'react';
import * as S from './ArrowDown.style';

const ArrowDown = ({ to }) => (
  <S.Container className="container">
    <S.StyledLink
      to={to}
      spy={true}
      smooth={true}
      offset={-100}
      duration={500}
      className="styled"
    >
      <S.Arrow size="3rem" />
    </S.StyledLink>
  </S.Container>
);

export default ArrowDown;
