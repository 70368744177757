import React from 'react';

import { ArrowDown, Banner, DownloadLink, Header } from './components';
import { AboutMe, Contacts, Projects, Resume } from './sections';

const App = () => {
  return (
    <>
      <Header />
      <Banner />
      <div className="container align-center">
        <DownloadLink
          text="Download my resume"
          url="https://server.venslaviciute.lt/uploads/agne_venslaviciute_cv_40d5887cbf.pdf"
        />
      </div>

      <ArrowDown to="about-me" />

      <div className="container">
        <AboutMe />
        <Contacts />
        <Resume />
        <Projects />
      </div>
    </>
  );
};

export default App;
