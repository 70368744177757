import React, { useState } from 'react';
import * as S from './ModalImage.style';

const ModalImage = ({ src, alt, size }) => {
  const [modalOpen, setModalOpen] = useState(false);

  // const openModal = () => {
  //   setModalOpen(true);
  // };

  // const closeModal = () => {
  //   setModalOpen(false);
  // };

  const toggleModal = () => {
    const body = document.body;

    !modalOpen ? (body.className = 'mobile-menu-open') : (body.className = '');

    setModalOpen(!modalOpen);
  };

  return (
    <S.ImageContainer>
      <S.Image src={src} alt={alt} size={size} onClick={() => toggleModal()} />

      {modalOpen && (
        <S.Modal onClick={() => toggleModal()}>
          <S.CloseButton onClick={() => toggleModal()}>&times;</S.CloseButton>

          <S.ModalImage src={src} alt={alt} />
        </S.Modal>
      )}
    </S.ImageContainer>
  );
};

export default ModalImage;
