import React from 'react';
import * as S from './SkillCard.style';

const SkillCard = ({ icon, title }) => {
  return (
    <S.Card hasIcon={!!icon}>
      {icon && <S.Logo>{React.cloneElement(icon, { size: '2rem' })}</S.Logo>}
      <S.Title hasIcon={!!icon}>{title}</S.Title>
    </S.Card>
  );
};

export default SkillCard;
