import styled from 'styled-components';

export const TimelineContainer = styled.div`
  @media only screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;

    & > * {
      width: calc(100% / 2 - 0.5rem);
      margin-bottom: 1rem;
    }

    & > *:not(:nth-child(2n)) {
      margin-right: 1rem;
    }
  }
`;
