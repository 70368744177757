import React from 'react';
import * as S from './Project.style';

import { IconLink } from '..';
import ModalImage from '../ModalImage/ModalImage';

import { FaGithub } from 'react-icons/fa';
// import { FiGlobe } from 'react-icons/fi';

const Project = ({
  image,
  alt,
  title,
  liveLink,
  frontLink,
  backLink,
  description,
}) => (
  <S.ProjectContainer>
    <S.ProjectImage>
      <ModalImage src={image} alt={alt} size={{ width: '100%' }} />
    </S.ProjectImage>

    <S.ProjectDetails>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>

      <S.Links>
        {/* {liveLink && (
          <IconLink icon={<FiGlobe />} url={liveLink} text="Live Site Link" />
        )} */}

        {frontLink && (
          <IconLink icon={<FaGithub />} url={frontLink} text="Front End Link" />
        )}

        {backLink && (
          <IconLink icon={<FaGithub />} url={backLink} text="Back End Link" />
        )}
      </S.Links>
    </S.ProjectDetails>
  </S.ProjectContainer>
);

export default Project;
