import React from 'react';
import * as S from './DesktopNavbar.style';

import { Link } from 'react-scroll';

const DesktopNavbar = ({ links }) => {
  return (
    <S.NavbarWrapper id="desk-nav">
      <S.Navbar>
        <div className="container">
          <Link to="desk-nav" smooth={true} duration={500} className="no-style">
            <S.LogoContainer>
              <S.Logo>A</S.Logo>

              <S.Title>
                <span>Agnė</span> Venslavičiūtė
              </S.Title>
            </S.LogoContainer>
          </Link>

          <S.LinksContainer>
            {links &&
              links.map((link) => (
                <S.StyledLink
                  key={link.id}
                  to={link.id}
                  activeclass="active"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  {link.title}
                </S.StyledLink>
              ))}
          </S.LinksContainer>
        </div>
      </S.Navbar>
    </S.NavbarWrapper>
  );
};

export default DesktopNavbar;
