import React from 'react';
import * as S from './IconLink.style';

const IconLink = ({ icon, url, text }) => {
  return (
    <S.IconLink>
      {url ? (
        <S.LinkUrl href={url} target="_blank" rel="noreferrer">
          <S.Icon>{icon}</S.Icon>
          {text || url}
        </S.LinkUrl>
      ) : (
        <S.LinkText>
          <S.Icon>{icon}</S.Icon>
          {text}
        </S.LinkText>
      )}
    </S.IconLink>
  );
};

export default IconLink;
