import React from 'react';

import DesktopNavbar from '../DesktopNavbar/DesktopNavbar';
import MobileNavbar from '../MobileNavbar/MobileNavbar';

const links = [
  {
    id: 'about-me',
    title: 'About Me',
  },
  {
    id: 'contacts',
    title: 'Contacts',
  },
  {
    id: 'resume',
    title: 'Resume',
  },
  {
    id: 'projects',
    title: 'Projects',
  },
];

const Header = () => {
  return (
    <header>
      <DesktopNavbar links={links} />
      <MobileNavbar links={links} />
    </header>
  );
};

export default Header;
