import React from 'react';
import * as S from './Banner.style';

const Banner = () => (
  <S.Section className="container">
    <S.Square>
      <S.Text>
        <span>
          <span className="primary-color">Hello</span>, I am a{' '}
          <span className="highlight">Junior Full-Stack Developer</span>
        </span>
      </S.Text>
    </S.Square>
  </S.Section>
);

export default Banner;
