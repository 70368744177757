import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  /* margin-top: 2.5rem; */
  /* padding: 2.5rem 0; */
  /* justify-content: center; */

  & > * {
    width: calc(100% / 2 - 0.5rem);
    margin-bottom: 1rem;
  }

  & > *:not(:nth-child(2n)) {
    margin-right: 1rem;
  }

  @media only screen and (min-width: 500px) {
    & > * {
      width: calc(100% / 3 - (2rem / 3));
      margin-bottom: 1rem;
    }

    & > *:not(:nth-child(2n)) {
      margin-right: 0;
    }

    & > *:not(:nth-child(3n)) {
      margin-right: 1rem;
    }
  }

  @media only screen and (min-width: 768px) {
    & > * {
      width: calc(100% / 5 - (4rem / 5));
      margin-bottom: 1rem;
    }

    & > *:not(:nth-child(3n)) {
      margin-right: 0;
    }

    & > *:not(:nth-child(5n)) {
      margin-right: 1rem;
    }
  }
`;

export const Title = styled.h4`
  color: #d9556c;
  font-size: 0.85rem;
  margin: 0.7rem 0;
`;
