import React, { useState } from 'react';
import * as S from './MobileNavbar.style';

import { Link } from 'react-scroll';

import { FaBars, FaTimes } from 'react-icons/fa';

const MobileNavbar = ({ links }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const mql = window.matchMedia('(min-width: 768px)');

  mql.addEventListener('change', (e) => {
    if (menuOpen && e.matches) {
      toggleMenu();
    }
  });

  const toggleMenu = () => {
    const body = document.body;

    !menuOpen ? (body.className = 'mobile-menu-open') : (body.className = '');

    setMenuOpen(!menuOpen);
  };

  const menuClass = menuOpen ? 'visible' : 'hidden';

  return (
    <S.NavbarWrapper id="mobile-nav">
      <S.Navbar id="navbar">
        <S.NavbarCont>
          <Link
            to="mobile-nav"
            smooth={true}
            duration={500}
            className="no-style"
          >
            <S.LogoContainer>
              <S.Logo>A</S.Logo>

              <S.Title>
                <span>Agnė</span> Venslavičiūtė
              </S.Title>
            </S.LogoContainer>
          </Link>

          <S.MobileIcon onClick={toggleMenu}>
            {menuOpen ? <FaTimes /> : <FaBars />}
          </S.MobileIcon>
        </S.NavbarCont>

        <S.LinksContainer id="links" className={`container ${menuClass}`}>
          {links &&
            links.map((link) => (
              <S.StyledLink
                key={link.id}
                to={link.id}
                activeclass="active"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={toggleMenu}
                className="styled"
              >
                {link.title}
              </S.StyledLink>
            ))}
        </S.LinksContainer>
      </S.Navbar>
    </S.NavbarWrapper>
  );
};

export default MobileNavbar;
