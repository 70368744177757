import styled from 'styled-components';

export const Section = styled.section`
  max-width: 900px;

  :not(:last-child) {
    margin-bottom: 4rem;
  }

  p {
    line-height: 1.5rem;
  }
`;

export const Title = styled.h2`
  color: #d9556c;
  letter-spacing: 0.3rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
`;

export const Subtitle = styled.h3`
  color: #d9556c;
  font-size: 1.2rem;
  margin-bottom: 2.5rem;
  margin-top: 4.5rem;
  text-transform: uppercase;
`;
