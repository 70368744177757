import styled from 'styled-components';

export const Section = styled.section`
  align-items: center;
  display: flex;
  margin: 0 auto;
  min-height: 60vmin;
`;

export const Square = styled.div`
  border: 0.2rem solid #d9556c;
  height: 30vh;
  position: relative;

  width: 50vw;

  @media only screen and (min-width: 500px) {
    height: 20rem;
    width: 15rem;
  }
`;

export const Text = styled.div`
  align-items: center;
  display: flex;
  font-size: 5vh;
  height: 30vh;
  left: 2.5rem;
  min-width: 40vw;
  position: absolute;

  @media only screen and (min-width: 500px) {
    font-size: 3rem;
    height: 20rem;
  }
`;
